import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (

 <Layout>
   	<SEO title="HOME" />
   	<h1 style={{textAlign:'center', letterSpacing: '0.8rem'}}>WELCOME</h1>
   	<div 
   		style={{
   			height: '10vh',
   			margin: 'auto',
   			display: 'flex',
   			flexDirection: 'row',
   			justifyContent: 'center',
            marginTop: '100px'
   		}}
   	>
   		<img src={`/../gifs/candle.gif`} style={{height: '100%', width: 'auto'}}/>   		
   		<img src={`/../gifs/skell.gif`} style={{height: '100%', width: 'auto'}}/>   		
   		<img src={`/../gifs/candle.gif`} style={{height: '100%', width: 'auto'}}/>
   	</div>
       <div
           style={{
               marginTop: '70px',
               textAlign: 'center',
               opacity: '0.4'
            }}
        >
           <div className="wavy-text"><i>"Where've you been?"</i></div>
            <svg width="100%" height="100%" style={{display:"none"}}>
                <defs>
                    <filter id="wavy" filterUnits="userSpaceOnUse" x="0" y="0">
                        <feTurbulence id="wave-animation" numOctaves="1" seed="1" baseFrequency="0.00"></feTurbulence>
                        <feDisplacementMap scale="10" in="SourceGraphic"></feDisplacementMap>
                    </filter>
                    <animate xlinkHref="#wave-animation" attributeName="baseFrequency" dur="3s" keyTimes="0;0.5;1" values="0.0 0.04; 0.0 0.07; 0.0 0.04" repeatCount="indefinite"></animate>
                </defs>
            </svg>
       </div>
   	<div>
   		
   	</div>
</Layout>
)

export default IndexPage;